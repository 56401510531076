//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-200:_3072,_5504,_6104,_7728,_4372,_3620,_9728,_1981;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-200')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-200', "_3072,_5504,_6104,_7728,_4372,_3620,_9728,_1981");
        }
      }catch (ex) {
        console.error(ex);
      }